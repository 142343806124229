import React from 'react';
import PropTypes from 'prop-types';
import SimpleTable from '../../general/SimpleTable';
import Price from '../../general/Price';
import {ProductDetail} from '../../../constants/propTypesDefinitions';
import {taxMultiplier as taxMultiplierFunc} from '../../../lib/project';
import {formatNumber, formatQuantity} from '../../../lib/formatting';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class ProductPricesTable extends React.PureComponent {
    static propTypes = {
        productDetails: ProductDetail,
    };

    render() {
        const {productDetails = {}} = this.props;
        const {prices = {}} = productDetails;
        const taxMultiplier = productDetails.tax_rate != null  ? taxMultiplierFunc(productDetails.tax_rate) : 1;
        let quantityPrices = [
            {
                label: <Trans>Minimum</Trans>,
                quantity: productDetails.quantity_min,
                price: prices.price_min,
                unit: productDetails.quantity_units,
            },
            {
                label: <Trans>Kartón</Trans>,
                quantity: productDetails.quantity_bulk,
                price: prices.price_bulk,
                unit: productDetails.quantity_units,
            },
            {
                label: <Trans>Paleta</Trans>,
                quantity: productDetails.quantity_pallet,
                price: prices.price_pallet,
                unit: productDetails.quantity_units,
            },
        ];
        const quantityPricesFiltered = quantityPrices.filter(
            priceDef => priceDef.quantity != null && priceDef.price != null
        );
        if(quantityPricesFiltered.length < 1) {
            return null;
        }
        return [
            <h3 key="label" className="pb-3 text-center"><Trans>Cenník</Trans></h3>,
            <SimpleTable
                key="table"
                data={quantityPricesFiltered}
                colDefs={
                    [
                        {
                            dataKeys: ['quantity', 'unit', 'label'],
                            render: (props) => {
                                return <div className="px-2 d-flex flex-wrap">
                                    <span className="mr-2">{props.label}</span>
                                    <span className="text-nowrap">
                                        {formatQuantity(props.quantity, props.unit)}
                                        {'+'}
                                    </span>
                                </div>;
                            }
                        },
                        {
                            dataKeys: 'price',
                            render: (props) => {
                                return <Price 
                                    price={props.price} 
                                    withVAT={false} 
                                    nullOption={<Trans>na dopyt</Trans>}
                                />;
                            }
                        },
                        {
                            dataKeys: 'price',
                            render: (props) => {
                                return <Price 
                                    price={props.price*taxMultiplier}
                                    withVAT={true}
                                    nullOption={<Trans>na dopyt</Trans>}
                                />;
                            }
                        }
                    ]
                }
            />
        ];
    }

}

export default ProductPricesTable;