import React from 'react';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import { RIGHTS } from '../../constants/Rights';
import FormModal from '../fetch/FormModal';
import Tooltip from '../general/Tooltip';
import generalFailedPC from '../fetch/generalFailedPC';
import {Button, Form, Icon} from 'antd';
import {
    formItemLayout,
    tailFormItemLayout,
    numberMandatoryRule,
    mandatoryRule,
} from '../../constants/form';
import CustomersSelect from '../project/CustomersSelect';
import { createFetchZebraPrintProductLabel } from '../../backend/apiCalls';
import { merge } from '../../lib/object';
import { currentDate } from '../../lib/date';
import InputQuantity from '../project/InputQuantity';
import DatePicker from '../general/DatePicker';

const FormItem = Form.Item;

/**
 * @dusan
 */

class PrintProductLabel extends React.PureComponent {
    static propTypes = {
        productId: PropTypes.number.isRequired,
        productLabel: PropTypes.string.isRequired,
        customerId: PropTypes.number,
        barcode: PropTypes.string,
        size: PropTypes.string,
        onFinish: PropTypes.func,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: "",
    }

    render() {
        const {size, onFinish, className, productId, productLabel, customerId, barcode} = this.props;
        return <RightsWrapper from={RIGHTS.WORKER}>
            <FormModal
                openNode={
                    <Tooltip title={<Trans>Vytlačiť štítok</Trans>}>
                        <Button size={size} className={className}>
                            <Icon type="printer"/>
                        </Button>
                    </Tooltip>
                }
                values={{productId, customerId, barcode}}
                onFinishSuccessful={onFinish}
                title={
                    <span>
                        <Trans>Vytlačiť štítok na</Trans>
                        {' '}
                        {productLabel}
                    </span>
                }
                Form={PrintProductLabelFormWrapped}
                Response={null}
                Failed={generalFailedPC(t`Nepodarilo sa vytvoriť produkt.`)}
            />
        </RightsWrapper>;
    }
}

export default PrintProductLabel;

class PrintProductLabelForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            productId: PropTypes.number.isRequired,
            customerId: PropTypes.number,
            barcode: PropTypes.string,
        }).isRequired,
    };


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0)
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                const {productId, barcode} = values;
                const checkedValues = merge(formValues, {id_product: productId, barcode: barcode});
                createFetchZebraPrintProductLabel()(checkedValues).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {values: {customerId}, onClose} = this.props;
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Počet</Trans>}
            >
                {getFieldDecorator('amount', {rules: [numberMandatoryRule], initialValue: 1})(
                    <InputQuantity 
                        ref={node => this.first = node}
                        unit={<Trans>ks</Trans>}
                        min={1}
                        step={1}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Zákazník</Trans>}
            >
                {getFieldDecorator('id_customer', {rules: [mandatoryRule], initialValue: customerId})(
                    <CustomersSelect/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Dátum výroby</Trans>}
            >
                {getFieldDecorator('produced_at', {initialValue: currentDate()})(
                    <DatePicker/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Dátum expirácie</Trans>}
            >
                {getFieldDecorator('expiration', {initialValue: null})(
                    <DatePicker/>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    {loading ? <Trans>Tlačí sa</Trans> : <Trans>Vytlačiť</Trans>}
                </Button>
            </FormItem>
        </Form>
    }
}

const PrintProductLabelFormWrapped = Form.create()(PrintProductLabelForm);