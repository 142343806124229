import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../general/Tooltip';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import ProductModalTable from '../ProductModalTable';
import { formatProductName } from '../../../lib/formatting';
import { Product } from '../../../constants/propTypesDefinitions';
import generalFailedPC from '../../fetch/generalFailedPC';
import FormModal from '../../fetch/FormModal';
import ProductVariantForm from './ProductVariantForm';
import { getQueryParamNumber } from '../../../lib/url';
import { QUERY_PARAMS } from '../../../constants/navigation';
import locationHOC from '../../locationProvider/locationHOC';

/**
 * @dusan
 */

class AddProductVariant extends React.PureComponent {
    static propTypes = {
        onFinish: PropTypes.func.isRequired,
        productId: PropTypes.number.isRequired,
        productLabel: PropTypes.string.isRequired,
    };

    render() {
        const {location, productId, productLabel, onFinish} = this.props;
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.ID_CUSTOMER);
        return <div className="mx-3 d-flex">
            <ProductModalTable
                productId={productId}
                customerId={customerId}
                initSearchText={productLabel}
                hideGeneric={true}
                modalTitle={<span>
                    <Trans>Vybrať varianty produktu</Trans>
                    {' '}
                    {productLabel}
                </span>}
                MainRowActions={AddProductVariantAction}
                onClose={onFinish}
                openNode={
                    <Tooltip title={<Trans>Pridať variant produktu</Trans>}>
                        <Button
                            size="small"
                            icon="plus"
                        />
                </Tooltip>}
            />
        </div>;
    }

}

export default locationHOC(AddProductVariant);

class AddProductVariantAction extends React.PureComponent {
    static propTypes = {
        product: Product.isRequired,
        productId: PropTypes.number.isRequired,
    };
    render() {
        const {productId, product} = this.props;

        return <FormModal
            openNode={
                <Button size="small" type="primary">
                    <Trans>Vybrať</Trans>
                </Button>
            }
            values={{
                isEdit: false,
                variant: {
                    id_product: productId,
                    id_variant: product.id,
                    designation: product.designation,
                    manufacturer: product.manufacturer,
                }
            }}
            title={
                <span>
                    <Trans>Pridať variant</Trans>
                    {' '}
                    {formatProductName(product.designation, product.manufacturer)}
                </span>
            }
            Form={ProductVariantForm}
            Failed={generalFailedPC(t`Nepodarilo sa pridať variant produktu.`)}
        />;
    }
}