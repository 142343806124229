import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../general/Tooltip';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import ProductModalTable from '../ProductModalTable';
import ButtonFetch from '../../fetch/ButtonFetch';
import { formatProductName } from '../../../lib/formatting';
import { createFetchProductAddRelative } from '../../../backend/apiCalls';
import { Product } from '../../../constants/propTypesDefinitions';
import generalFailedPC from '../../fetch/generalFailedPC';

/**
 * @fero
 */

class AddRelatedProduct extends React.PureComponent {
    static propTypes = {
        onFinish: PropTypes.func.isRequired,
        customerId: PropTypes.number,
        productId: PropTypes.number.isRequired,
        productDesignation: PropTypes.string.isRequired,
        productManufacturer: PropTypes.string.isRequired,
    };

    render() {
        const { customerId, productId, productDesignation, productManufacturer, onFinish} = this.props;
        return <div className="mx-3 d-flex">
            <ProductModalTable
                productId={productId}
                productDesignation={productDesignation}
                productManufacturer={productManufacturer}
                customerId={customerId}
                modalTitle={<span>
                    <Trans>Vybrať produkty súvisiace s</Trans>
                    {' '}
                    {formatProductName(productDesignation, productManufacturer)}
                </span>}
                MainRowActions={AddRelatedProductAction}
                onClose={onFinish}
                openNode={
                    <Tooltip title={<Trans>Vytvoriť prepojenie</Trans>}>
                        <Button
                            size="small"
                            icon="plus"
                        />
                </Tooltip>}
            />
        </div>;
    }

}

export default AddRelatedProduct;

class AddRelatedProductAction extends React.PureComponent {
    static propTypes = {
        product: Product.isRequired,
        productId: PropTypes.number.isRequired,
        productDesignation: PropTypes.string.isRequired,
        productManufacturer: PropTypes.string.isRequired,
    };
    render() {
        const {productId, productDesignation, productManufacturer, product} = this.props;
        return <ButtonFetch
            size="small"
            type="primary"
            values={{
                product: {designation: productDesignation, manufacturer: productManufacturer},
                relative: { designation: product.designation, manufacturer: product.manufacturer}
            }}
            Response={AddRelatedProductResponse}
            Failed={generalFailedPC(t`Vytvorenie prepojenia zlyhalo`)}
            fetchFunction={createFetchProductAddRelative(
                productId,
                product.id
            )}
        >
            <Trans>Vybrať</Trans>
        </ButtonFetch>;
    }
}

class AddRelatedProductResponse extends React.PureComponent {
    static propTypes = {
        values: PropTypes.shape({
            product: PropTypes.shape({
                designation: PropTypes.string.isRequired,
                manufacturer: PropTypes.string.isRequired,
            }).isRequired,
            relative: PropTypes.shape({
                designation: PropTypes.string.isRequired,
                manufacturer: PropTypes.string.isRequired,
            }).isRequired
        }).isRequired,
    };

    render() {
        const {values} = this.props;
        const product = values.product != null ? values.product : {};
        const relative = values.relative != null ? values.relative : {};
        return <div>
            <h4>{t`Vytvorené prepojenie`}</h4>
            <div>
                {formatProductName(product.designation, product.manufacturer)}
                {' <-> '}
                {formatProductName(relative.designation, relative.manufacturer)}
            </div>
        </div>;
    }
}