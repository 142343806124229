import React from 'react';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';
import HistoryModal from '../../history/HistoryModal';
import locationHOC from '../../locationProvider/locationHOC';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import {RIGHTS} from '../../../constants/Rights';
import {ProductDetail} from '../../../constants/propTypesDefinitions';
import PageActionsLayout from '../../general/PageActionsLayout';
import { navigateToParametrized } from '../../../lib/url';
import StockDetails from '../../project/productTable/StockDetails';
import ProductOrdersModal from '../../project/productTable/ProductOrdersModal';
import ProductQuotationsModal from '../../project/productTable/ProductQuotationsModal';
import ProductRequestsModal from '../../project/productTable/ProductRequestsModal';
import ProductReplacementsModal from '../../project/productTable/ProductReplacementsModal';
import { formatProductName } from '../../../lib/formatting';
import Link from '../../navigation/Link';
import CopyProduct from '../CopyProduct';
import RemoveProduct from '../RemoveProduct';
import EditProduct from '../EditProduct';
import ProductTransfersModal from '../../project/productTable/ProductTransfersModal';
import PurchasePricesModal from '../../project/productTable/PurchasePricesModal';
import ProductProductsModal from '../../project/productTable/ProductProductsModal';

/**
 * @fero
 */

class ProductDetailsActions extends React.PureComponent {
    static propTypes = {
        customerId: PropTypes.number,
        productDetails: ProductDetail.isRequired,
        reload: PropTypes.func.isRequired,
        isVariant: PropTypes.bool.isRequired,
    };

    render() {
        const {location, customerId, productDetails, isVariant, reload} = this.props;
        const productId = productDetails.id;
        const productLabel = formatProductName(productDetails.designation, productDetails.manufacturer);
        const pathItems = Array.isArray(productDetails.path) ? productDetails.path : [];
        const altCategories = Array.isArray(productDetails.alt_categories) ? productDetails.alt_categories : [];
        return <div className="d-flex justify-content-between full-size-width">
            <div className="p-3">
                <div className="d-flex flex-wrap">
                {
                    pathItems.map((obj, idx) => {
                        return <div key={idx} className="text-nowrap">
                            <Link
                                className="text-secondary"
                                to={ROUTES.HOME}
                                queryParams={{
                                    [QUERY_PARAMS.ID_PRODUCT_GROUP]: obj.id,
                                }}
                            >
                                {obj.name}
                            </Link>
                            { idx < pathItems.length-1 ?
                                <span className="px-2">/</span> : 
                                null
                            }
                        </div>;
                    })
                }
                </div>
                {
                    altCategories.map((obj, idx) => {
                        return <Link
                            key={idx}
                            className="text-secondary"
                            to={ROUTES.HOME}
                            queryParams={{
                                [QUERY_PARAMS.ID_PRODUCT_GROUP]: obj.id_product_group,
                            }}
                        >
                            {obj.name}
                        </Link>;
                    })
                }
            </div>
            <PageActionsLayout
                actions={[
                    {
                        rightsFrom: RIGHTS.DEALER,
                        node: <HistoryModal
                            objectId={productId}
                            objectName={formatProductName(productDetails.designation, productDetails.manufacturer)}
                            objectType="product"
                        />
                    },
                    {
                        rightsFrom: RIGHTS.MARKETING,
                        node: <StockDetails
                            productId={productId}
                            productDesignation={productLabel}
                            label={<Trans>Sklad</Trans>}
                            reload={reload}
                        />
                    },
                    {
                        rightsFrom: RIGHTS.MARKETING,
                        node: <ProductTransfersModal
                            productId={productId}
                            productDesignation={productLabel}
                            label={<Trans>Pohyby</Trans>}
                        />
                    },
                    {
                        rightsFrom: RIGHTS.MARKETING,
                        node: <ProductOrdersModal
                            productId={productId}
                            productDesignation={productLabel}
                            label={<Trans>Objednávky</Trans>}
                        />
                    },
                    {
                        rightsFrom: RIGHTS.MARKETING,
                        node: <ProductQuotationsModal
                            productId={productId}
                            productDesignation={productLabel}
                            label={<Trans>Cenové ponuky</Trans>}
                        />
                    },
                    {
                        rightsFrom: RIGHTS.MARKETING,
                        node: <ProductRequestsModal
                            productId={productId}
                            productDesignation={productLabel}
                            label={<Trans>Dopyty</Trans>}
                        />
                    },
                    {
                        node: <ProductReplacementsModal
                            customerId={customerId}
                            productId={productId}
                            productDesignation={productDetails.designation}
                            productManufacturer={productDetails.manufacturer}
                            label={<Trans>Alternatívy</Trans>}
                        />
                    },
                    {
                        rightsFrom: RIGHTS.MARKETING,
                        node: <ProductProductsModal
                            productId={productId}
                            productDesignation={productDetails.designation}
                            productManufacturer={productDetails.manufacturer}
                            label={<Trans>Výrobky</Trans>}
                        />
                    },
                    {
                        rightsFrom: RIGHTS.MARKETING,
                        node: <PurchasePricesModal
                            productId={productId}
                            productDesignation={productDetails.designation}
                            productManufacturer={productDetails.manufacturer}
                        />
                    },
                    {
                        rightsFrom: RIGHTS.MARKETING,
                        node: <CopyProduct
                            product={productDetails}
                            onFinish={(newProductId) => {
                                if(!isVariant)
                                    navigateToParametrized(location, null, {[QUERY_PARAMS.ID_PRODUCT]: newProductId});
                            }}
                        />
                    },
                    {
                        rightsFrom: RIGHTS.MARKETING,
                        node: <RemoveProduct
                            product={productDetails}
                            onFinish={() => {
                                if(!isVariant)
                                    navigateToParametrized(location, ROUTES.HOME, {});
                            }}
                        />
                    },
                    {
                        rightsFrom: RIGHTS.MARKETING,
                        node: <EditProduct
                            product={productDetails}
                            onFinish={(newProductId) => {
                                if(!isVariant)
                                    navigateToParametrized(location, null, {[QUERY_PARAMS.ID_PRODUCT]: newProductId});
                                reload();
                            }}
                        />
                    },
                ]}
            />
        </div>;
    }

}

export default locationHOC(ProductDetailsActions);