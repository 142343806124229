import React from 'react';
import PropTypes from 'prop-types';
import ShoppingCartAdd from '../../table/ShoppingCartAdd';
import ProductCustomization from '../../table/ProductCustomization';
import SpecialPrice from '../../../project/productTable/SpecialPrice';
import Price from '../../../general/Price';
import withDataHOC from '../../../dataProvider/withDataHOC';
import {priceByProductCount} from '../../../../lib/project';
import {taxMultiplier as taxMultiplierFunc} from '../../../../lib/project';
import {GLOBAL_DATA} from '../../../../constants/globalData';
import {ProductVariants, TradeProduct} from '../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import ProductVariantsSelect from '../../../project/ProductVariantsSelect';
import AddProductVariant from '../../../project/productVariants/AddProductVariant';

/**
 * @fero
 */

class DealerPriceAndAddCart extends React.PureComponent {
    static propTypes = {
        productId: PropTypes.number.isRequired,
        productLabel: PropTypes.string.isRequired,
        numberOfItems: PropTypes.number.isRequired,
        onChangeNumberOfItems: PropTypes.func.isRequired,
        productData: TradeProduct,
        variants: ProductVariants,
        onVariantChange: PropTypes.func,
        stockId: PropTypes.number,
    };

    render() {
        const {productId, productLabel, productData = {}, stockId, variants, onVariantChange, onChangeNumberOfItems, numberOfItems} = this.props;
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        const unitPrice = priceByProductCount(productData.prices, numberOfItems, null, productData.stock_options, stockId);
        const taxMultiplier = productData.tax_rate != null ? taxMultiplierFunc(productData.tax_rate) : 1;

        return <div className="">
            { productData.id_form != null ? 
                <ProductCustomization
                    product={productData}
                    label={<Trans>Nastavenie možností</Trans>}
                /> : 
                null
            }
            <div className="d-flex align-items-center pb-3">
                <h3 className="mr-2 text-secondary">
                    <Trans>Varianty:</Trans>
                </h3>
                <AddProductVariant
                    productId={productId}
                    productLabel={productLabel}
                    onFinish={() => reload([GLOBAL_DATA.PRODUCT_DETAILS])}
                />
            </div>
            <ProductVariantsSelect
                className="full-size-width ml-2"
                variants={variants}
                value={productData}
                onChange={onVariantChange}
                reload={() => reload([GLOBAL_DATA.PRODUCT_DETAILS])}
                showActions={true}
                allowClear={true}
            />
            <div className="my-3 d-flex align-items-center">
                <h3 className="mr-2 text-secondary font-weight-bold">
                    <Trans>Cena bez DPH:</Trans>
                </h3>
                <h3>
                    <SpecialPrice
                        isRefPrice={false}
                        data={productData}
                        stockId={stockId}
                        reload={() => reload([GLOBAL_DATA.PRODUCT_DETAILS])}
                        numberOfItems={numberOfItems}
                    />
                </h3>
            </div>
            <div className="my-2 d-flex align-items-center">
                <span className="mr-2 text-secondary font-weight-bold">
                    <Trans>Cena s DPH:</Trans>
                </span>
                <Price 
                    className="text-nowrap"
                    price={unitPrice * taxMultiplier}
                    nullOption={<Trans>na dopyt</Trans>} 
                />
            </div>
            <ShoppingCartAdd
                className="my-2 d-flex"
                numberOfItems={numberOfItems}
                onChangeNumberOfItems={onChangeNumberOfItems}
                onFinish={() => reload([GLOBAL_DATA.CART_CURRENT])}
                product={productData}
                stockId={stockId}
                size="large"
                label={<span className="mr-2"><Trans>Do košíka</Trans></span>}
            />
        </div>;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(DealerPriceAndAddCart);