import React from 'react';
import PropTypes from 'prop-types';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import DisplayModal from '../../general/DisplayModal';
import Image from '../../general/Image';

/**
 * @fero
 */

class ProductImage extends React.PureComponent {
    static propTypes = {
        [SESSION_ATTRIBUTES.IS_MOBILE]: PropTypes.bool,
        src: PropTypes.string,
    };

    render() {
        const {src, [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile} = this.props;
        return isMobile ? 
        <Image
            className="product-detail-full-image"
            src={src}
        /> :
        <DisplayModal
            openNode={
                <Image
                    className="product-detail-image cursor-pointer"
                    src={src}
                />
            }
            closeLabel={null}
            closable={false}
        >
            <Image
                className="product-detail-full-image"
                src={src}
            />
        </DisplayModal>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.IS_MOBILE])(ProductImage);