import React from 'react';
import SimpleTable from '../../general/SimpleTable';
import {Components} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro'
import Link from '../../navigation/Link';
import { QUERY_PARAMS, ROUTES } from '../../../constants/navigation';
import { formatProductName } from '../../../lib/formatting';

/**
 * @fero
 */

class ProductComponentsTable extends React.PureComponent {
    static propTypes = {
        components: Components,
    };

    render() {
        const {components} = this.props;
        return <React.Fragment>
            <h3 className="pb-3 text-center"><Trans>Zložky produktu</Trans></h3>
            <SimpleTable
                data={components}
                colDefs={
                    [
                        {
                            style: {width: '40%'},
                            dataKeys: ['id_component', 'designation', 'manufacturer'],
                            render: (data) => {
                                return <Link
                                    className="mx-2 text-dark"
                                    to={ROUTES.PRODUCT_DETAILS}
                                    queryParams={{[QUERY_PARAMS.ID_PRODUCT]: data.id_component}}
                                    title={<Trans>Zobraziť detail komponentu</Trans>}
                                >
                                    {formatProductName(data.designation, data.manufacturer)}
                                </Link>;
                            }
                        },
                        {
                            style: {width: '20%'},
                            dataKeys: ['quantity', 'quantity_units']
                        },
                        {
                            style: {width: '40%'},
                            dataKeys: 'notice'
                        }
                    ]
                }
            />
        </React.Fragment>;
    }

}

export default ProductComponentsTable;