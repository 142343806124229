import React from 'react';
import PropTypes from 'prop-types';
import {ProductDetail} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import { formatQuantity } from '../../../lib/formatting';

/**
 * @fero
 */

class Packaging extends React.PureComponent {
    static propTypes = {
        productDetails: ProductDetail.isRequired,
    };

    render() {
        const {productDetails} = this.props;
        const min = productDetails.quantity_min;
        const bulk = productDetails.quantity_bulk;
        const pallet = productDetails.quantity_pallet;
        const units = productDetails.quantity_units;
        const step = productDetails.quantity_div;
        if (min == null && bulk == null && pallet == null) {
            return null;
        } else {
            return <div className="d-flex">
                <div className="mr-2 text-secondary font-weight-bold"><Trans>Balenie:</Trans></div>
                <div className="d-flex flex-wrap full-size-width">
                {min != null ?
                    <div className="text-nowrap">
                        <span className="m-2"><Trans>minimum</Trans></span>
                        {formatQuantity(min, units)}
                        {bulk != null || pallet != null ? ', ' : null}
                    </div> :
                    null
                }
                {bulk != null ?
                    <div className="text-nowrap">
                        <span className="m-2"><Trans>kartón</Trans></span>
                        {formatQuantity(bulk, units)}
                        {pallet != null ? ', ' : null}
                    </div> :
                    null
                }
                {pallet != null ?
                    <div className="text-nowrap">
                        <span className="m-2"><Trans>paleta</Trans></span>
                        {formatQuantity(pallet, units)}
                    </div> :
                    null
                }
                </div>
            </div>;
        }
    }

}

export default Packaging;