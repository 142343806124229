import React from 'react';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';
import {ProductRelative, ProductRelatives} from '../../constants/propTypesDefinitions';
import AddRelatedProduct from './relatedProducts/AddRelatedProduct';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import RemoveRelatedProduct from './relatedProducts/RemoveRelatedProduct';
import { RIGHTS } from '../../constants/Rights';
import ProductTile from './productTable/ProductTile';
import { merge } from '../../lib/object';
import TradeActions from '../products/table/TradeActions';

/**
 * @dusan
 */

class RelatedProducts extends React.PureComponent {
    static propTypes = {
        customerId: PropTypes.number,
        productId: PropTypes.number, // if product params is not given, add button is not shown
        productDesignation: PropTypes.string,
        productManufacturer: PropTypes.string,
        productRelatives: ProductRelatives.isRequired,
        reload: PropTypes.func.isRequired,
    };

    render() {
        const {productRelatives, productId, productDesignation, productManufacturer, customerId, reload} = this.props;
        return <div className="full-size-witdh">
            <div className="d-flex align-items-center justify-content-center pb-3">
                <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                { productRelatives != null && productRelatives.length > 0 ?
                    <h3><Trans>Súvisiace produkty</Trans></h3> : null
                }
                </RightsWrapper>
                <RightsWrapper from={RIGHTS.MARKETING}>
                    <h3 className="pr-2">
                        <Trans>Súvisiace produkty</Trans>
                    </h3>
                    { productId != null ?
                        <AddRelatedProduct
                            onFinish={reload}
                            customerId={customerId}
                            productId={productId}
                            productDesignation={productDesignation}
                            productManufacturer={productManufacturer}
                        /> : null
                    }
                </RightsWrapper>
            </div>
            <div className="d-flex flex-wrap full-size-width">
            { productRelatives.map((data, idx) => {
                    const dataWithId = merge(data, {id: data.id_relative});
                    return <ProductTile
                        key={idx}
                        data={dataWithId}
                        reload={reload}
                        MainRowActions={RowActions}
                    />
                })
            }
            </div>
        </div>;
    }
}

export default RelatedProducts;

class RowActions extends React.PureComponent {
    static propTypes = {
        product: ProductRelative.isRequired,
        reload: PropTypes.func.isRequired,
        numberOfItems: PropTypes.number,
        onChangeNumberOfItems: PropTypes.func.isRequired,
    };

    render() {
        const {product, reload, numberOfItems, onChangeNumberOfItems} = this.props;
        return <div className="d-flex justify-content-between full-size-width">
            <TradeActions
                product={product}
                reload={reload}
                numberOfItems={numberOfItems}
                onChangeNumberOfItems={onChangeNumberOfItems}
            />
            <RightsWrapper from={RIGHTS.MARKETING}>
                <RemoveRelatedProduct
                    onFinish={reload}
                    relatedProduct={product}
                />
            </RightsWrapper>
        </div>;
    }
}