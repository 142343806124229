import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import CustomerPriceAndAddCart from './priceAndAddCart/CustomerPriceAndAddCart';
import DealerPriceAndAddCart from './priceAndAddCart/DealerPriceAndAddCart';
import {ProductDetail, ProductVariants} from '../../../constants/propTypesDefinitions';
import {RIGHTS} from '../../../constants/Rights';

/**
 * @fero
 */

class PriceAndAddCart extends React.PureComponent {
    static propTypes = {
        productId: PropTypes.number.isRequired,
        productLabel: PropTypes.string.isRequired,
        productData: ProductDetail.isRequired,
        stockId: PropTypes.number,
        variants: ProductVariants,
        onVariantChange: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            numberOfItems: 0,
        }
    }

    componentDidMount() {
        this.setState({
            numberOfItems: Number(this.props.productData.quantity_min)
        });
    }

    componentDidUpdate(prevProps) {
        const prevQuantityMin = Number(prevProps.productData.quantity_min);
        const quantityMin = Number(this.props.productData.quantity_min);
        if(quantityMin != prevQuantityMin) {
            this.setState({
                numberOfItems: quantityMin
            });
        }
    }

    onChangeNumberOfItems = (numOfItems) => {
        const quantityMin = Number(this.props.productData.quantity_min);
        this.setState({
            numberOfItems: numOfItems != null ? Number(numOfItems) : quantityMin,
        });
    };

    render() {
        const {productId, productLabel, stockId, productData, variants, onVariantChange} = this.props;
        const {numberOfItems} = this.state;
        return [
            <RightsWrapper key="customer" to={RIGHTS.DISTRIBUTOR}>
                <CustomerPriceAndAddCart
                    numberOfItems={numberOfItems}
                    onChangeNumberOfItems={this.onChangeNumberOfItems}
                    productData={productData}
                    stockId={stockId}
                    variants={variants}
                    onVariantChange={onVariantChange}
                />
            </RightsWrapper>,
            <RightsWrapper key="dealer" from={RIGHTS.MARKETING}>
                <DealerPriceAndAddCart
                    productId={productId}
                    productLabel={productLabel}
                    numberOfItems={numberOfItems}
                    onChangeNumberOfItems={this.onChangeNumberOfItems}
                    productData={productData}
                    stockId={stockId}
                    variants={variants}
                    onVariantChange={onVariantChange}
                />
            </RightsWrapper>,
        ];
    }

}

export default PriceAndAddCart;